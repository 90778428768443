import { useEffect, useState } from "react";
import { Fade, chakra } from "@chakra-ui/react";

// UI
import { Header, Footer } from "ui";

const LoadingImages = [
  "https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/battle_rope.jpg",
  "https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/fist_bump.jpg",
];

const SecondLoadingImages = [
  "https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/stretch.jpg",
  "https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/mat_stretch.jpg",
  "https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/watch.jpg",
];

const Layout = (props) => {
  const [imagesLoaded, setImagesLoaded] = useState(1);
  const [mounted, setMounted] = useState(false);

  const loadImages = ({ imageList, onload }) => {
    for (const _image of imageList) {
      const img = new Image();
      img.src = _image;
      if (onload) img.onload = onload;
    }
  };

  useEffect(() => {
    loadImages({
      imageList: LoadingImages,
      onload: () => setImagesLoaded(imagesLoaded + 1),
    });
  }, []);

  useEffect(() => {
    if (imagesLoaded === 2) {
      loadImages({ imageList: SecondLoadingImages });
    }
  }, [imagesLoaded]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Fade
      in={mounted}
      transition={{
        enter: { opacity: 0 },
        exit: {
          opacity: 1,
          transition: { duration: 1 },
        },
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header />
      <chakra.main flex={1}>{props.children}</chakra.main>
      <Footer />
    </Fade>
  );
};
export default Layout;
